

.termsconditionsWrapper{
    margin: 100px 0px;
}

.terms-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  color: #333;
}

.terms-container h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #2c3e50;
}

.terms-container h2 {
  font-size: 1.75rem;
  margin-top: 30px;
  color: #34495e;
}

.terms-container p {
  line-height: 1.6;
  margin-bottom: 20px;
  font-size: 1rem;
  color: $theme-color;
}

.terms-container ul {
  padding-left: 20px;
  margin-bottom: 20px;
}

.terms-container ul li {
  margin-bottom: 10px;
  font-size: 1rem;
}

.terms-container a {
  color: #2980b9;
  text-decoration: none;
}

.terms-container a:hover {
  text-decoration: underline;
}
