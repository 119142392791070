.slider-wrapper {
    position: relative;
    overflow: hidden;
    height: 100vh;
  }
  
  .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
  }
  
  .video-background video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .slide {
    position: relative;
    z-index: 1;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .inner {
    color: #fff;
    text-align: center;
    animation: slideIn 1.5s ease-in-out forwards;
  }
  
  @keyframes slideIn {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .heroSlider .title {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .description {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
  
  .btn-primary {
    padding: 10px 20px;
    background-color: #28a745;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary:hover {
    background-color: #218838;
  }
  