.h-divider {
  margin: auto;
  margin-top: 80px;
  width: 80%;
  position: relative;
}

.h-divider .shadow {
  overflow: hidden;
  height: 20px;
  box-shadow: none !important;
}

.h-divider .shadow:after {
  content: "";
  display: block;
  margin: -25px auto 0;
  width: 100%;
  height: 25px;
  border-radius: 125px/12px;
  box-shadow: 0 0 8px black;
}





.h-divider .text2 {
  width: 60px;
  height: 60px;
  position: absolute;
  bottom: 100%;
  margin-bottom: -35px;
  left: 50%;
  margin-left: -25px;
  border-radius: 100%;
  box-shadow: 0 2px 4px #999;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-divider img {
  position: absolute;
  margin: 4px;
  max-width: 50px;
  border-radius: 100%;
}
