/*=====================
    Header Area 
=======================*/

.btn-bangladesh {
  text-transform: lowercase;
  color: #f42a41 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 900;
  transition: background-color 0.3s ease, color 0.3s ease;
}

a:hover{
  text-decoration: none !important;
}
.btn-bangladesh:hover {
  background-color: #005825;
}

.btn-mobile-bangladesh {
  display: none;
}

.logo-mobile-menu{
  display: none;
}

@media only screen and (max-width: 991px) {
  .btn-bangladesh {
    display: none;
  }

  .btn-mobile-bangladesh {
    display: block;
  }

  .logo-mobile-menu{
    display: block;
    position: absolute;
    top: -40px;

  }

  .logo-mobile-menu img{
    max-width: 120px;
  }
}

.header-area {
  &.header--transparent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: auto;
    padding-left: 50px;
    padding-right: 50px;
    z-index: 999;
  }

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0;
    flex-wrap: wrap;
  }

  .logo {
    a {
      img {
        @media #{$sm-layout} {
          width: 120px;
        }
      }
    }
  }

  // &.header--static {
  //   padding: 0 50px;
  // }

  &.header--fixed {
    position: absolute;
    top: 0;
    z-index: 999;
    padding-left: 50px;
    padding-right: 50px;
    width: 100%;
    background: transparent;
    transition: 0.3s;

    .header-wrapper {
      padding: 20px 0;
      .logo a img {
        transition: 0.5s;
      }
      .mainmenunav ul.mainmenu > li > a::after {
        position: absolute;
        content: "";
        left: 0;
        width: 0;
        height: 3px;
        background: $theme-color;
        transition: 0.3s;
        bottom: 0;
      }
      .mainmenunav ul.mainmenu > li.is-current > a {
        color: $theme-color;
      }
    }

    &.sticky {
      position: fixed;
      background: transparent;
      .header-wrapper {
        padding: 0;
      }
      .logo a img {
        height: 50px;
      }
    }

    &.stickybg {
      position: fixed;
      background: white;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      .header-wrapper {
        padding: 0;
      }

      .mainmenunav ul.mainmenu > li > a {
        color: rgb(29, 29, 36) !important;
        font-weight: 600;
      }
      .logo a img {
        height: 50px;
      }
    }
  }
}

.header-right {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

/*-----------------------------
  Mobile Menu Style Css 
-------------------------------*/

.header-area {
  .header-wrapper {
    .mainmenunav {
      @media #{$md-layout}, #{$sm-layout} {
        position: absolute;
      }

      ul.mainmenu {
        @media #{$md-layout}, #{$sm-layout} {
          width: 60vw;
          height: 100vh;
          position: fixed;
          top: 0;
          right: -61vw;
          z-index: 99;
          padding: 55px;
          background: #ffffff;
          transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
          display: block;
          overflow-y: auto;
        }

        li {
          @media #{$md-layout}, #{$sm-layout} {
            margin: 0;
            padding: 10px 0;
          }

          ul.submenu {
            @media #{$md-layout}, #{$sm-layout} {
              position: relative;
              height: 0;
              transition: 0.3s;
              padding: 0;
              opacity: 0;
              visibility: hidden;
              background: transparent;
              box-shadow: inherit;
            }

            &.active {
              @media #{$md-layout}, #{$sm-layout} {
                height: 100%;
                padding: 12px 0;
                opacity: 1;
                visibility: visible;
              }
            }
          }

          a {
            @media #{$md-layout}, #{$sm-layout} {
              padding: 0;
              display: block;
            }
          }
        }
      }
    }

    .close-menu {
      position: fixed;
      top: 12px;
      opacity: 0;
      visibility: hidden;
      right: 29px;
      z-index: -10;
    }

    &.menu-open {
      .close-menu {
        opacity: 1;
        visibility: visible;
        z-index: 9999;
        span {
          color: $theme-color;
          font-size: 30px;
          cursor: pointer;
        }
      }

      .mainmenunav ul.mainmenu {
        @media #{$md-layout}, #{$sm-layout} {
          right: 0;
        }
        @media #{$large-mobile} {
          width: 100vw;
          padding: 54px 20px;
        
        }
      }
    }
  }
}

/*------------------------
  Hamberger Menu  
---------------------------*/

.humberger-menu {
  span {
    cursor: pointer;
    @media #{$sm-layout}, #{$md-layout} {
      font-size: 28px;
    }
  }
}

.header-wrapper.menu-open .humberger-menu span {
  opacity: 0;
}

.mainmenu .active a {
  position: relative;
  font-weight: bold !important;
  color: $theme-color !important;
}

.mainmenu .active::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: $theme-color;
}

.mainmenunav {
  ul.mainmenu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @extend %liststyle;
    flex-wrap: wrap;
    > li {
      margin: 0 15px;
      position: relative;
      > a {
        @extend %transition;
        font-size: 16px;
        font-weight: 600;
        padding: 16px 0;
        display: inline-block;
      }
      &:hover > a {
        color: $theme-color;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      > ul.submenu {
        @extend %liststyle;
        min-width: 220px;
        height: auto;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 90;
        opacity: 0;
        visibility: hidden;
        background-color: white;
        box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
        padding: 12px 0;
        border-radius: 10px;
        @extend %transition;
        li {
          a {
            font-size: 14px;
            padding: 5px 20px;
            color: #1f1f25;
            color: $theme-color !important;
            font-weight: 600;
            margin: 0 10px;
            border-radius: 3px;
            @extend %transition;
          }
          &:hover > a {
            color: white !important;
            background: $theme-color;
            display: block;
            width: 90%;
          }
        }
      }
      &:hover > ul.submenu {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.humberger-menu span.text-white {
  color: $theme-color !important;
}
