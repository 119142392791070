/*====================
    About Area 
======================*/

.rn-finding-us-area {
  .inner {
    position: relative;
    padding-left: 240px;
    padding-top: 110px;
    padding-bottom: 110px;

    @media #{$lg-layout} {
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      flex-direction: column-reverse;
    }

    @media #{$md-layout} {
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      flex-direction: column-reverse;
    }

    @media #{$sm-layout} {
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      flex-direction: column-reverse;
    }
    .content-wrapper {
      .content {
        background: #272527;
        padding: 120px 100px;
        position: relative;
        z-index: 2;
        width: 800px;
        border-radius: 10px;

        @media #{$lg-layout} {
          padding: 60px 50px;
          width: 100%;
          border-radius: 0;
        }

        @media #{$md-layout} {
          padding: 60px 50px;
          width: 100%;
          border-radius: 0;
        }

        @media #{$sm-layout} {
          padding: 30px 30px;
          width: 100%;
          border-radius: 0;
        }

        h4 {
          color: #ffffff;
          font-size: 36px;
          font-weight: 500;
          margin-bottom: 20px;
          @media #{$sm-layout} {
            font-size: 26px;
            margin-bottom: 12px;
          }
        }
        p {
          font-size: 18px;
          color: #ffffff;
          font-weight: 300;
          margin-bottom: 41px;
          padding-right: 10%;
          @media #{$sm-layout} {
            margin-bottom: 24px;
            padding-right: 0;
          }
        }
      }
    }
    .thumbnail {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;

      @media #{$lg-layout} {
        position: static;
        height: 100%;
      }

      @media #{$md-layout} {
        position: static;
        height: 100%;
      }

      @media #{$sm-layout} {
        position: static;
        height: 100%;
      }
      img {
        border-radius: 10px;
        @media #{$lg-layout} {
          border-radius: 0;
        }
        @media #{$md-layout} {
          border-radius: 0;
        }
        @media #{$sm-layout} {
          border-radius: 0;
        }
      }
    }
  }
}

.whatwedoWrapper .HEADtitle {
  margin-top: 200px;
  margin-bottom: 20px;
}
.about-inner {
  @media #{$md-layout} {
    padding-top: 40px;
  }
  @media #{$sm-layout} {
    padding-top: 10px;
  }

  h2 {
    &.title {
      margin-bottom: 8px;
    }
  }
  p {
    &.description {
      font-weight: 300;
      color: #717173;
      text-align: justify;
      a {
        color: rgba(29, 29, 36, 1);
        &:hover {
          color: $theme-color;
        }
      }
    }
  }
}
.whatwedotextboxModalwrapper {
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
.whatwedotextboxModalh3 {
  font-size: 22px;
  text-align: center;
  margin-top: 8px;
}

.whatwedotextboxModalp {
  text-align: justify;
}
.whatwedotextModal {
  h3 {
    font-size: 22px;
  }
}
.whatwedotextboxModalImageDiv {
  text-align: center;
}
.whatwedotextbox {
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    margin-top: 14px;
  }
}
.whatwedotextbox {
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: $theme-color;
    border-radius: 10px;
    transform: scale(1.05);
    cursor: pointer;
    .about-inner h3,
    p {
      color: white !important;
      transition: color 0.3s ease;
    }
  }
}

// .whatwedotextbox.asdf-3:hover {
//   background-color: $theme-color;

//   .about-inner h3,
//   .about-inner p {
//     color: black !important;
//     transition: color 0.3s ease;
//   }
// }

.whatwedorow {
  margin: 100px 0px;
}
.whatwedoWrapper .about-inner .title {
  font-size: 28px;
  text-align: left;
}
.whatwedoWrapper .thumbnail {
  position: relative;
  display: inline-block;
}

.readMoreBTN {
  background-color: $theme-color;
  color: white;
  padding: 10px;
}

.whatwedoWrapper .rightLine .about-inner .readMoreBTN,
.whatwedoWrapper .rightLine .about-inner h3,
.whatwedoWrapper .rightLine .about-inner p {
  text-align: left;
}

.whatwedoWrapper .leftLine .about-inner .readMoreBTN,
.whatwedoWrapper .leftLine .about-inner h3,
.whatwedoWrapper .leftLine .about-inner p {
  text-align: right;
}

@media screen and (max-width: 768px) {
  .whatwedoWrapper .about-inner .title {
    font-size: 28px;
    text-align: center;
    margin-bottom: 40px;
  }
  .whatwedoWrapper .thumbnail::before {
    display: none;
  }
  .whatwedoWrapper .leftLine .thumbnail::before {
    display: none;
  }
  .whatwedoWrapper .rightLine .about-inner .readMoreBTN,
  .whatwedoWrapper .rightLine .about-inner h3,
  .whatwedoWrapper .rightLine .about-inner p {
    text-align: center;
  }

  .whatwedoWrapper .leftLine .about-inner .readMoreBTN,
  .whatwedoWrapper .leftLine .about-inner h3,
  .whatwedoWrapper .leftLine .about-inner p {
    text-align: center;
  }
  .section-title {
    padding: 0px;
  }
}

.about-wrapper {
  .thumbnail {
    position: relative;
    // z-index: 2;
    border: 2px solid $theme-color;
    padding: 10px;
    img {
      border-radius: 8px;
    }
  }
}

.about-position-top {
  @media #{$lg-layout} {
    padding-top: 120px;
  }
  @media #{$md-layout} {
    padding-top: 80px;
  }
  @media #{$sm-layout} {
    padding-top: 60px;
  }
  .thumbnail {
    margin-top: -130px;
    @media #{$lg-layout} {
      margin-top: 0;
    }
    @media #{$laptop-device} {
      margin-top: -89px;
    }
    @media #{$md-layout} {
      margin-top: 0;
    }
    @media #{$sm-layout} {
      margin-top: 0;
    }
  }
}

.about-us-list {
  margin-top: 15px;
  h3 {
    &.title {
      font-size: 30px;
      @extend %fontWeight500;
      margin-bottom: 20px;
      @media #{$sm-layout} {
        font-size: 26px;
        margin-bottom: 8px;
      }
    }
  }
  p {
    font-weight: 300;
    color: rgba(29, 29, 36, 0.75);
  }
}

.rn-content-box-style--1 {
  .content {
    text-align: left;
    padding: 0 120px;
    @media #{$md-layout} {
      padding: 0 60px;
    }
    @media #{$sm-layout} {
      padding: 0 40px;
    }
    p {
      &.subtitle {
        color: $heading-color;
        font-size: 16px;
        font-weight: 400;
        background: rgba(255, 26, 80, 0.03);
        padding: 8px 25px;
        display: inline-block;
        border-radius: 4px;
        span {
          color: $theme-color;
        }
      }
    }
    h2 {
      margin-top: 30px;
      margin-bottom: 20px;
      font-size: 36px;
      line-height: 50px;
      font-weight: 500;
      @media #{$lg-layout} {
        margin-top: 7px;
      }
      @media #{$md-layout} {
        margin-top: 7px;
      }
      @media #{$sm-layout} {
        margin-top: 7px;
      }
    }
    // p {
    //   color: rgba(29, 29, 36, 0.75);
    //   font-size: 18px;
    //   line-height: 30px;
    // }
  }
}

@media screen and (max-width: 768px) {
  .whatwedoWrapper .HEADtitle {
    margin-top: 250px;
    margin-bottom: 50px;
    font-size: 40px;
  }

  .whatwedorow {
    margin: 50px 0px;
    margin-top: 20px;
  }
}
