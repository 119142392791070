.privacyPolicyWrapper {
  margin: 120px 0px;
}

.privacyPolicyWrapper p {
  text-align: justify;
  font-style: italic;
  color: $theme-color;
}

.privacyPolicyWrapper .ppDivOne {
  background-color: #ebf4f6;
  padding: 40px;
  border-radius: 20px;
  margin-bottom: 20px;
}
.privacyPolicyWrapper .ppDivTwo {
  background-color: #e3f0ff;
  padding: 40px;
  border-radius: 20px;
  margin-bottom: 20px;
  margin-top: -20px;
}
.privacyPolicyWrapper .ppDivThree {
  background-color: #cbdadb;
  padding: 40px;
  border-radius: 20px;
  margin-bottom: 20px;
  margin-top: -20px;
}

@media screen and (max-width: 768px) {
  .privacyPolicyWrapper .ppDivOne {
    padding: 20px;

    margin-bottom: 20px;
  }
  .privacyPolicyWrapper .ppDivTwo {
    padding: 20px;

    margin-bottom: 20px;
    margin-top: -20px;
  }
  .privacyPolicyWrapper .ppDivThree {
    padding: 20px;
    margin-bottom: 20px;
    margin-top: -20px;
  }
}
