.counterHome .counterROW {
  position: absolute;
  bottom: -120px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  pointer-events: none;
}
.counterAbout .counterROW {
  position: absolute;
  bottom: 240px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  pointer-events: none;
}

.counterHome .counterROW .counter {
  font-size: 42px;
  color: $theme-color;
}
.counterAbout .counterROW .counter {
  font-size: 42px;
  color: $theme-color;
}

.counterROW .description {
  font-size: 18px;
  font-weight: 600;
}

.counterup_style--1 {
  background-color: #f0f6f2;
  box-shadow: rgba(0, 88, 37, 0.2) 0px 12px 30px 5px;
  border-radius: 10px;
  text-align: center;
  z-index: 999;
  pointer-events: auto;
  margin: 10px;
  padding: 20px 0px;
  flex: 0 0 18%; /* Force 4 items per row on large screens */
  max-width: 22%; /* Ensures 4 items per row */
}

/* For tablet devices */
@media (max-width: 992px) {
  .counterup_style--1 {
    flex: 0 0 30%; /* 3 cards per row on medium screens */
    max-width: 30%;
  }
}

/* For smaller tablets and large phones */
@media (max-width: 768px) {
  .counterHome .counterROW {
    bottom: -320px;
  }

  .counterAbout .counterROW {
    bottom: -40px;
  }

  .counterTWOMAINDIV {
    margin-bottom: 360px;
  }

  .counterup_style--1 {
    flex: 0 0 44%;
    max-width: 45%;
    padding: 6px;
  }

  .counterHome .counterROW .counter {
    font-size: 32px;

  }
  .counterAbout .counterROW .counter {
    font-size: 32px;

  }
  

  .counterROW .description {
    font-size: 14px;
  }


}
