.team-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 23px;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.team-card {
  position: relative;
  width: 250px; 
  height:280px; 
  perspective: 1000px;
  margin: 0px 20px;

}

.card-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.team-card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card-front {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 10px;
  border-radius: 16px;
}

.card-back {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transform: rotateY(180deg);
  padding: 20px;
}

.team-wrapper .thumbnail {
  width: 120px;
  height: 120px;
  border-radius: 50%; 
  border: 5px solid $theme-color;
  transition: opacity 0.3s ease-in-out;
  background: url(../../images/team/chairman.jpg);
  background-position: center;
  background-size: cover; 
}



.team-name {
  font-size: 1.2rem;
  margin: 0;
  color: black;
  margin-top:16px;
}

.team-wrapper .content {
  text-align: center;
}

.team-wrapper .title {
  font-size: 1.2rem;
  margin: 0.5rem 0;
}

.team-wrapper .designation {
  font-size: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}

.social-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.social-item {
  display: inline-block;
}

.social-link {
  color: #000; 
  font-size: 1.2rem;
  transition: color 0.3s;
}

.social-link:hover {
  color: $theme-color;
}

