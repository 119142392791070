
.product-card {
  border: 1px solid #ddd;
  padding: 16px;
  margin: 8px;
  text-align: center;
  width: 300px;
  height: 370px;
}

.product-card .product-name {
  font-size: 20px;
  margin-top: 5px;
}

.product-image {
  width: 210px;
  height: 200px;
}

.learn-more-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
}

.product-details {
  padding: 16px;
}

.product-description {
  margin-top: 16px;
}



@media screen and (max-width:768px) {


  .product-card {
    border: 1px solid #ddd;
    padding: 8px;
    margin: 0px;
    text-align: center;
    width: auto;
    height: 330px;
  }

  .product-image {
    width: 150px;
    height: 150px;
  }

  .product-card .product-name {
    font-size: 18px;
    margin-top: 5px;
  }
}
