.market-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  margin: 80px 0px;
  gap: 20px;
}

.image-container {
  position: relative;
  flex: 1;
}

.image1,
.image2 {
  width: 90%;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.image1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.image2 {
  position: relative;
  top: 30px;
  left: 30px;
  z-index: 1;
}

.business-content-container {
  flex: 2;
  padding: 0 30px;
}

.checkIconExport {
  max-width: 30px;
  margin-right: 8px;
}

.business-content-container h1 {
  color: $theme-color;
  font-size: 36px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0px;
}

.business-content-container p {
  font-size: 16px;
  margin-bottom: 20px;
}

.business-feature-list {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
}

.business-feature-list li {
  width: 100%;
  font-size: 16px;
  display: inline;
}



/* WhyBoxes Start - Adjusted for card-like appearance */
.WhyBoxes {
  margin: 40px auto; /* Adjust space after the title */
  max-width: 400px; /* Make it card-sized */
  width: 100%;
  height: auto;
}

.WhyBoxes .wrap {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%; /* Adjusted width to fit card size */
  height: auto; /* Adjust height to auto for content */
  margin: 1.5rem auto;
  border: 5px solid;
  border-image: linear-gradient(
      -50deg,
      green,
      #00b300,
      forestgreen,
      green,
      lightgreen,
      #00e600,
      green
    )
    1;
  position: relative;
  overflow: hidden;
}

.WhyBoxes .overlay {
  display: flex;
  width: 100%;
  height: auto; /* Adjust to content size */
  padding: 1rem;
  background: #186218;
}

.WhyBoxes .overlay-content {
  width: auto;
  padding: 0.5rem;
  border: 3px solid;
  border-image: linear-gradient(
      to bottom,
      #aea724 5%,
      forestgreen 35% 65%,
      #aea724 95%
    )
    0 0 0 100%;
}

.WhyBoxes .image-content {
  width: 150px; /* Smaller image */
  height: 150px; /* Fixed size for image */
  background-size: cover;
}

.WhyBoxes .text {
  padding: 1rem;
  height: auto;
  max-height: 200px; /* Limit max height for scroll */
  background: #fff;
  box-shadow: inset 1px 1px 15px 0 rgba(0 0 0 / 0.4);
  overflow-y: auto;
}

.WhyBoxes .dots {
  right: 1rem;
  bottom: 1rem;
  width: 40px;
  height: 20px;
}

/* WhyBoxes End */

.WhyBoxes .wrap:hover .overlay {
  transform: translateX(-60vmin);
}
.WhyBoxes .wrap:hover .image-content {
  width: 30vmin;
}
.WhyBoxes .wrap:hover .overlay-content {
  border: none;
  transition-delay: 0.2s;
  transform: translateX(60vmin);
}
.WhyBoxes .wrap:hover .dots {
  transform: translateX(1rem);
}
.WhyBoxes .wrap:hover .dots .dot {
  background: white;
}

/* Animations and timing delays */
.animate {
  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}
/* Pop In */
.pop {
  animation-name: pop;
}
@keyframes pop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

/* Slide In */
.slide {
  animation-name: slide;
}
@keyframes slide {
  0% {
    opacity: 0;
    transform: translate(4em, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

/* Slide Left */
.slide-left {
  animation-name: slide-left;
}
@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translate(-40px, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.slide-up {
  animation-name: slide-up;
}
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(3em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.delay-1 {
  animation-delay: 0.3s;
}
.delay-2 {
  animation-delay: 0.6s;
}
.delay-3 {
  animation-delay: 0.9s;
}
.delay-4 {
  animation-delay: 1.2s;
}
.delay-5 {
  animation-delay: 1.5s;
}
.delay-6 {
  animation-delay: 1.8s;
}
.delay-7 {
  animation-delay: 2.1s;
}
.delay-8 {
  animation-delay: 2.4s;
}

// WhyBoxes End

.exportBottomText {
  background-color: $theme-color;
  margin-top: 40px;
  padding: 20px;
  border-radius: 20px;
}

.exportBottomText p {
  font-size: 24px;
  color: white;
  text-align: center;
}

.exportBottomContact {
  margin-top: 40px;
}
.exportBottomContact p {
  font-size: 24px;
}
.exportBottomContact span {
  font-size: 36px;
  color: $theme-color-2;
}

@media screen and (max-width: 768px) {
  .market-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    margin: 80px 0px;
    gap: 70px;
  }

  .business-content-container {
    flex: 2;
    padding: 0 0px;
  }
}

.exportcards-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between; 
}

.exportCARD {
  position: relative;
  flex: 0 1 calc(33.333% - 20px); 
  height: 450px; 
  perspective: 1000px;
}
.exportCARD .title{
  text-align: center;
}
.exportCARD .card-front .title{
  text-align: center;
  font-size: 38px;
}
.card-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.exportCARD:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card-front {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  box-shadow: rgba(0, 88, 37, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  z-index: 2;
}

.card-back {
  background-color: rgba(255, 255, 255, 0.9); /* Optional */
  padding: 20px;
  border-radius: 10px;
  transform: rotateY(180deg);
  z-index: 1;
}

.card-back .content {
  text-align: center;
}

/* Responsive Styles */
@media (max-width: 992px) {
  .exportCARD {
    flex: 1 1 calc(50% - 20px); /* 2 cards in a row for smaller screens */
  }
}

@media (max-width: 576px) {
  .exportCARD {
    flex: 1 1 100%; /* 1 card in a row for the smallest screens */
  }
}
