.products-wrapper {
  padding: 20px;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0px;
  align-items: center;
  justify-content: center;
}

.modal-close-btn {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
}

@media (max-width: 1200px) {
  .products-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .products-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.moreProductBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;

  .moreProductBtn {
    background-color: #015a27;
    color: white;
    padding: 12px 24px;
    border-radius: 4px;
    margin-top: 40px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  .learnmoreProductBtn {
    background-color: #015a27;
    color: white;
    padding: 12px 24px;
    border-radius: 4px;
    margin-top: 10px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  @media screen and (max-width: 768px) {
    .learnmoreProductBtn {
      background-color: #015a27;
      color: white;
      padding: 12px 12px;
      border-radius: 4px;
      margin-top: 10px;
      transition: background-color 0.3s ease, transform 0.3s ease;
    }
  }

  .learnmoreProductBtn:hover {
    background-color: #86b22b;
    transform: translateY(-5px);
  }
  .moreProductBtn:hover {
    background-color: #86b22b;
    transform: translateY(-5px);
  }
}

.productShowcaseRowWraPPER {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* gap: 16px; */
}

@media screen and (max-width: 768px) {
  /* .ReactModal__Content {
    width: 60%;
    max-width: 800px;
    max-height: 90vh; 
  } */

  .modal-close-btn {
    font-size: 18px;
  }
  .HOMEproduct-card-wrapper .product-card {
    margin-bottom: 20px;
  }

  .products-wrapper {
    margin-top: 30px;
  }
}

.product-card-wrapper {
  flex: 1 1 calc(25% - 16px);
  box-sizing: border-box;
}

@media (max-width: 1200px) {
  .product-card-wrapper {
    flex: 1 1 calc(50% - 16px);
  }
}

@media (max-width: 480px) {
  .product-card-wrapper {
    flex: 1 1 100%;
  }
}
