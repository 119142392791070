/* Style for category image containers */
.category-image-container {
  position: relative;
  cursor: pointer;

  margin: 20px 0px;
}

.category-image {
  width: 100%;
  border-radius: 8px;
  transition: opacity 0.3s ease;
}

.category-image-container:hover .category-image {
  opacity: 0.8;
}

/* Style for category titles */
.category-title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
}

/* Style for the modal */
.category-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  background: white;
  padding: 20px;
  border-radius: 8px;
  overflow: auto;
  z-index: 1000;
}

/* Style for the modal overlay */
.category-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75); /* Semi-transparent background */
  z-index: 999; /* Ensure it's below the modal */
}

/* Style for the close button */
.close-modal-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: #ff0000;
  cursor: pointer;
  font-size: 24px;
  z-index: 1001; /* Ensure it's on top of modal */
}

/* Style for images in the modal */
.modal-image {
  max-width: 100%;
  max-height: 100%;
  margin: 10px 0;
}

.ataglancepopup-main {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.left-panel {
  border-radius: 20px;
  padding: 20px;
  width: 40%;
  text-align: center;
  background-color: #f0f6f2;
}

.ataglancepopup-image {
  width: 100%;
  height: auto;
}

.ataglancepopup-title {
  margin: 10px 0;
  font-size: 24px;
  color: black;
}

.description {
  font-size: 16px;
  color: gray;
}

.right-panel {
  padding: 20px;
border-radius: 20px;
  width: 50%;
  position: relative;
  background-color: #f0f6f2;
}

.slider-item {
  text-align: center;
}

.ataglancepopup-slider-image {
  height: 300px;
  object-fit: cover;
  width: 100%;
}

.ataglace-slider-image-text {
  color: black;
  font-size: 26px;
}

/* Specific styling for arrows in the AtaGlance slider */

.ataglance-slider .slick-prev,
.ataglance-slider .slick-next {
  font-size: 0; 
}

/* Ensure the arrow icons still appear */
.ataglance-slider .slick-prev:before,
.ataglance-slider .slick-next:before {
  font-size: 24px;
  color: green;
  padding: 10px;
  border-radius: 50%;
}

/* Position the next arrow */
.ataglance-slider .slick-prev {
  position: absolute; 
  right: -20px;
  top: 50%; 
  transform: translateY(-50%); 
  z-index: 10; 
}

/* Ensure there’s no left property conflicting */
.ataglance-slider .slick-prev.slick-arrow {
  left: auto; 
}

/* Next button styles */
.ataglance-slider .slick-next {
  position: absolute; 
  right: -20px; 
  top: 60%;
  transform: translateY(-50%); 
  z-index: 10; 
}


