.about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../images/aboutus.jpg);
  background-position: center;
  background-size: cover;
  padding: 20px;
  margin-top: 250px;
  margin-bottom: 120px;
}

.about-content {
  display: flex;
  max-width: 1100px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  padding: 4px 20px;
}

.about-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.about-text {
  flex: 2;
  padding: 30px;
}

.about-text h2 {
  font-size: 32px;
  margin-bottom: 15px;
}

.about-text p {
  font-size: 16px;
  color: black;
  margin-bottom: 20px;
  text-align: justify;
}

.mission-vision {
  display: flex;
  justify-content: space-between;
}

.mission,
.vision {
  flex: 1;
  padding: 20px;
  text-align: center;
}

.mission i,
.vision i {
  font-size: 24px;
  margin-bottom: 10px;
  color: $theme-color;
}

.mission h3,
.vision h3 {
  font-size: 20px;
  color: $theme-color;
  margin-bottom: 10px;
}

.mission p,
.vision p {
  font-size: 16px;
  color: black;
  text-align: justify;
}

.mission {
  border-right: 2px solid #f0f0f0;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.vision {
  border-left: 2px solid #f0f0f0;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.mission:hover {
  background-color: $theme-color;
  & h3,
  p {
    color: white;
  }

  border-radius: 6px;
}
.vision:hover {
  background-color: $theme-color-2;
  & h3,
  p {
    color: white;
  }

  border-radius: 6px;
}

.brand-personality-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.brandCARD {
  background-color: white;
  box-shadow: rgba(0, 88, 37, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  padding: 20px;
  flex: 1 1 calc(33.333% - 20px);
  box-sizing: border-box;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.brand-personality {
  padding: 20px;
  transition: background-color 0.3s ease;
}
.brandCARD:hover {

    background-color: $theme-color;
    border-radius: 10px;

  .brand-personality h3 {
    color: white;
  }
  .brand-personality p {
    color: white;
  }
}




@media (max-width: 992px) {
  .brandCARD {
    flex: 1 1 calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .certificationsWrapper{
    flex-direction: column;
    margin-left: 0px !important;
  }


  .certificationsWrapper img{
    max-width: 200px !important;
    margin-left: 0px !important;
  }
  .about-image img{
    max-height: 300px;
  }
  .mission-vision{
    display: flex;
    flex-direction: column;
  }
  .about-content{
    flex-direction: column;
    padding: 20px;
  }
  .about-container {
    margin-top: 400px;
  }
  .about-container .about-text h2 {
    text-align: center;
  }
  .about-container .about-text p {
    text-align: justify;
  }
  .about-text {

    padding: 8px;
  }
  .brandCARD {
    flex: 1 1 calc(50% - 20px);
  }
}

@media (max-width: 576px) {
  .brandCARD {
    flex: 1 1 100%;
  }
}
