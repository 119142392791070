.lifestylebrandTextWrapper {
  background: url(../images/lifestyle.png);
  background-position: center;
  background-size: cover;
  height: 600px;
  width: 100%;
  margin: 80px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lifestylebrandText {
  padding: 20px;
  background-color: $theme-color;
  border-radius: 20px;
}

.lifestylebrandText h2 {
  font-size: 22px;
  color: white;
}

.upcomingProductCard {
  background-color: white;
  width: 240px;
  height: 160px;
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  flex-direction: column;
  transition: background-color 0.3s ease; /* Add smooth transition */
}

.upcomingProductCard:hover {
  background-color: #d4edda; /* Light green on hover */
}

.upcomingProductCardWrapper {
  padding: 20px;
  border-radius: 20px;
  display: flex;
}

@media (max-width: 768px) {

  .lifestylebrandTextWrapper {
    background: url(../images/lifestyle.png);
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
    margin: 60px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .upcomingProductCard {
    width: 180px;
    height: 120px;
  }

  .upcomingProductCardWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin: 0px 20px;
  }
}
