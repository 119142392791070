/* Main Page Title Area */
.article-details__hero-section {
  position: relative;
  background-size: cover;
  background-position: center;
  color: #fff;
}

.article-details__title-wrapper h2 {
  font-size: 36px;
  font-weight: bold;
  color: #fff;
}

.article-details__meta {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
}

.article-details__meta li {
  margin: 0 10px;
  font-size: 18px;
}

.article-details__meta li svg {
  margin-right: 5px;
  color: white;
}

/* Article Content */
.article-details__content-section {
  margin-top: 60px;
}

.article-details__content img {
  width: 100%;
  height: 400px;
}

.article-details__quote {
  margin-top: 40px;
  padding: 20px;
  font-style: italic;
  background-color: #f9f9f9;
  border-left: 5px solid #28a745;
}

/* Sidebar */

.article-details__recent-posts-widget {
  background-color: #f8f6f0;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
}

.article-details__categories-widget {
  background-color: #f8f6f0;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
}
.article-share-buttons {
  background-color: #f8f6f0;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
}
.article-details__categories-widget li {
  list-style-type: none;
  font-weight: 500;
}

.article-details__sidebar {
  height: 100%;
  padding-left: 30px;
}

.article-details__widget {
  margin-bottom: 40px;
}

.article-details__widget h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.article-details__widget ul {
  list-style-type: none;
  padding: 0;
}

.article-details__widget ul li {
  margin-bottom: 10px;
}

.article-details__widget ul li a {
  color: #333;
  text-decoration: none;
  transition: color 0.3s;
}

.article-details__widget ul li a:hover {
  color: #28a745;
}

/* Tags */

.article-details__tags-widget {
  background-color: #f8f6f0;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
}
.article-details__tags-widget ul {
  list-style: none;
  padding: 0;
}

.article-details__tags-widget ul li {
  display: inline-block;
  margin-right: 10px;
}

.article-details__tags-widget ul li a {
  padding: 5px 15px;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  color: #333;
  text-decoration: none;
}

.article-details__tags-widget ul li a:hover {
  background-color: #28a745;
  color: white;
  border-color: #28a745;
}

/* Comment Form */

.article-details__comment-form {
  margin-top: 40px;
}

.article-details__comment-title {
}
.article-details__comment-form input,
.article-details__comment-form textarea {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  background-color: #f8f6f0;
}

.article-details__comment-form textarea {
  height: 150px;
}

.article-details__comment-form button {
  padding: 12px 30px;
  background-color: $theme-color;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.article-details__comment-form button:hover {
  background-color: $theme-color-2;
}

.rn-blog-area {
  margin-top: 140px;
  padding: 30px;
}



.articleTitle {
  margin-bottom: 40px;
}

.rn-news-area {
  margin-top: 80px;
  margin-bottom: 40px;
  padding: 30px;
}

.blog-style--1 .thumbnail img{
  height: 260px;
  width: 300px;
}


@media screen and (max-width:768px){
  .article-details__sidebar {
    height: 100%;
    padding-left: 0px;
  }
  .article-share-buttons {
    background-color: #f8f6f0;
    border-radius: 20px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .article-details__content img {
    width: 100%;
    height: 300px;
  }
}