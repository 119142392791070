.farmhouseHeroWrapper {
  margin-top: 80px;
  margin-bottom: 20px;
}

.farmhouse-hero-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 20px;
  margin-top: 40px;
  border-radius: 20px;
  box-shadow: rgba(49, 49, 49, 0.2) 0px 7px 29px 0px;
}
.big-text-container {
  position: relative;
  flex: 1;
  border-right: 1px solid $theme-color;
  padding: 20px;
  background: url(../images/investbg.png);
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  overflow: hidden;
}


.big-text-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  border-radius: 20px;
}

.big-text-container h1 {
  position: relative;
  z-index: 2; 
  font-size: 42px;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
  text-align: center;
  color: white;
}

.small-text-content-container {
  flex: 1;
  padding: 20px;
}
.small-text-content-container p {
  font-weight: 500px;
  font-size: 24px;
}

.howitworkswrapper {
  margin-bottom: 140px;
  margin-top: 120px;
}
.howitworksdetails {
  text-align: center;
}
.howitworks {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
}

.howitworks ul li {
  list-style: none;
  font-size: 22px;
}

.howLeft {
  flex: 1;
  background-color: $theme-color;
  color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.howitworks h3 {
  text-align: center !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 2px solid white;
  text-transform: uppercase;
  color: white;
}

.howRight {
  flex: 1;
  background-color: $theme-color;
  color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.processcontainerwrapper {
  margin-top: 120px;
  margin-bottom: 140px;
}
// PROCESS START
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,700&display=swap");

.investmentProcessWrapper {
  margin: 30px 0px;
}

.process {
  $bem-block: &;
  border-radius: 20px;
  width: 100%;
  padding: 0 15px;
  text-align: center;
  background-color: #f8f8f8;

  &__item {
    user-select: none;
    text-align: center;
    position: relative;
    padding: 10px 22px;
    transition: 0.4s ease-in-out;

    &:hover {
      background: darken($theme-color, 5%);
    }

    &:hover #{$bem-block}__number {
      transform: translateY(5px);
      color: white;
    }
    &:hover #{$bem-block}__title {
      transform: translateY(5px);
      color: white;
    }
    &:hover #{$bem-block}__subtitle {
      transform: translateY(5px);
      color: white;
    }
  }

  &__number {
    font-size: 90px;
    -webkit-text-stroke: 1px $theme-color;
    display: block;
    color: transparent;
    font-family: "Roboto Condensed";
    font-weight: 700;
    transition: 0.4s ease-in-out;
  }

  &__title {
    display: block;
    font-family: "Roboto Condensed";
    font-weight: 700;
    letter-spacing: 1.5px;
    font-size: 26px;
    color: $theme-color;
    text-transform: uppercase;
    margin-top: 10px;
  }

  &__subtitle {
    display: block;
    font-size: 18px;
    color: darken($theme-color-2, 45%);
    margin-top: 20px;
    line-height: 1.5;
  }
}

@media (min-width: 768px) {
  .process {
    display: inline-block;

    &__item {
      width: 49%;
      display: inline-block;
    }
  }
}

@media (min-width: 1200px) {
  .process {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__item {
      width: 100%;

      &:not(:last-of-type) {
        &::after {
          content: "";
          width: 1px;
          height: 75%;
          background: darken($theme-color-2, 45%);
          position: absolute;
          right: 0;
          top: 50%;
          opacity: 0.2;
          transform: translateY(-50%);
        }
      }
    }
  }
}

// PROCESS ENd

@media screen and (max-width: 768px) {
  .farmhouse-hero-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 10px;
    flex-direction: column;
  }

  .big-text-container {
    flex: 1;
    border: none;
    border-bottom: 1px solid $theme-color;
    padding: 20px;
  }
  .small-text-content-container {
    flex: 1;
    padding: 20px;
  }
  .big-text-container h1 {
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 3px;
    text-transform: uppercase;
    text-align: center;
  }

  .small-text-content-container p {
    font-weight: 500px;
    font-size: 20px;
    text-align: center;
  }
  .howitworks {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
  }

  
.howLeft {
    flex: 1;
    background-color: #274c5c;
    color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .howitworks h3 {
    text-align: center !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 2px solid white;
    text-transform: uppercase;
    color: white;
  }
  
  .howRight {
    flex: 1;
    background-color: #274c5c;
    color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
}
