.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  z-index: 1001;
}

.modal-close-btn:hover {
  color: #000;
}

.getaQuaoteModalButton {
  background-color: #015a27;
  color: white;
  font-weight: 600;
  padding: 10px;
  border-radius: 8px;
  margin-top: 20px;
}

.product-details-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.product-details-image-container {
  flex: 1;
  max-width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.product-details-image-container img {
  max-height: 350px;
  border-radius: 16px;
}

.product-details-info {
  padding: 20px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: start; */

}
// .product-details-info {
//   padding: 20px;
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
// }
.productNameDesc {
  margin-bottom: 10px;
  overflow-y: scroll;
}

.productNameDesc h2 {
  font-size: 28px;
  text-transform: uppercase;
  margin-bottom: 18px;
}

.productNameDesc p {
  font-size: 16px;
  text-align: justify;
}

/* Mobile styles */
@media (max-width: 768px) {
  .product-details-info {
    padding: 20px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* align-items: start; */
    overflow-y: scroll;
  }
  .productDetails-slider .slick-list {
    height: 50px !important;
  }
  .product-details-wrapper {
    padding: 10px; /* Reduce padding on smaller screens */
  }

  .product-details {
    flex-direction: column;
    gap: 10px;
    width: 100%; /* Ensure it uses full width on mobile */
    box-shadow: none; /* Remove box shadow on mobile */
  }

  .product-details-image-container {
    max-width: 100%;
    padding: 0px;
  }

  .product-details-image {
    width: 100px;
    height: auto;
    max-height: 200px; /* Reduce image size for mobile */
  }

  .product-details-info h2 {
    font-size: 22px;
    text-align: center;
  }

  .product-details-info p {
    font-size: 14px;
    text-align: justify;
    margin-top: 0px;
    padding: 0 10px; /* Adjust padding for better readability */
  }

  .product-details-slider-image {
    max-width: 40px;
    max-height: 40px;
    margin: 0 4px;
  }

  .productDetails-slider {
    margin-top: 20px;
  }

  .getaQuaoteModalButton {
    margin-top: 16px;
    width: 100%; /* Make the button full-width on mobile */
  }
}

@media (max-width: 480px) {
  .product-details-wrapper {
    padding: 5px; /* Further reduce padding for very small screens */
  }

  .product-details {
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }

  .product-details-image-container {
    padding: 12px;
  }

  .product-details-image {
    max-height: 150px;
  }

  .product-details-info h2 {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .product-details-info p {
    font-size: 12px !important;
    padding: 0 8px;
  }

  .product-details-slider-image {
    max-width: 50px;
    max-height: 50px;
    margin: 0 2px;
  }

  .productDetails-slider {
    margin-top: 15px;
  }

  .getaQuaoteModalButton {
    margin-top: 12px;
  }
}

.product-details-image-container .slick-slider {
  width: 100%;
  margin-top: 20px;
}

/* Modal Mobile Responsiveness */
@media (max-width: 768px) {
  .product-details-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .product-details-image-container {
    width: 100%;
    margin-bottom: 15px;
  }

  .product-details-image-container img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .product-details-info h2 {
    font-size: 1.5rem;
  }

  .product-details-info p {
    font-size: 1rem;
  }

  .getaQuaoteModalButton {
    font-size: 1rem;
    padding: 10px 15px;
  }
}

/* Further adjustments for very small screens (e.g. phones) */
@media (max-width: 480px) {
  .product-details-info h2 {
    font-size: 1.25rem;
  }

  .product-details-info p {
    font-size: 0.9rem;
  }

  .getaQuaoteModalButton {
    font-size: 0.9rem;
    padding: 8px 12px;
  }
}

.productsDetailsModal {
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 10px;
  width: 80%;
  min-height: 50vh;
  max-height: 70vh;
  // height: 70vh;
  display: flex;
  // align-items: center;
  overflow-y: auto;
  background: #fff;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.quoteModalModal {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 10px;
  width: 50%;
  max-height: 80vh;
  overflow-y: auto;
  background: #fff;
  overflow: visible;
  -webkit-overflow-scrolling: touch;
}

.productsDetailscustomOverlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.quoteModalcustomOverlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

.react-responsive-modal-closeButton {
  display: none;
}
.CloseProductModalBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10000012;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: black;
}
.CloseQuoteModalBtn {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 10000012;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: black;
}

@media screen and (max-width: 768px) {
  .productsDetailsModal {
    position: fixed;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    border-radius: 10px;
    width: 90%;
    min-height: 50vh;
    max-height: 80vh;
    // height: 70vh;
    display: flex;
    // align-items: center;
    overflow-y: auto;
    background: #fff;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .quoteModalModal {
    position: fixed;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    border-radius: 10px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
    background: #fff;
    overflow: visible;
    -webkit-overflow-scrolling: touch;
  }
}
