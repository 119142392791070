.bangldeshEvolution {
  margin-top: 120px;

  padding: 40px;
}

.heritagebd .service-one-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.heritagebd .container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.heritageSingle{
  display: flex;
  align-items: center;
  justify-content: center;
}

.bangldeshEvolution .container {
  background-color: white;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
}
.bangldeshEvolution p {
  font-size: 22px;
  text-align: justify;
}

.heritagebd {
  margin-top: 120px;
  background-color: #f0f6f2 !important;
}

.bangldeshAtaglancewrapper {
  margin-top: 120px;
}

.bangldeshService {
  border-radius: 16px;
  background-color: #005825;
  height: 240px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}




.bangldeshService h3 {
  color: white;
}
