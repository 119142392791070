/*--------------------
Footer Styles 
----------------------*/

.footer-wrapper {
  position: relative;
}

.main-footer-wrapper {
  p {
    color: white;
    font-size: 14px;
    opacity: 0.75;
  }

  background-color: $theme-color;
  padding: 20px 0px;
  
}

.main-footer-wrapper .text{
  text-align: start;
}
.main-footer-wrapper .text a{
  color: white;
}





.main-footer-wrapper .logo img{
    max-width: 200px;
}

.copyright-text {
  position: absolute;
  bottom: -114px;

  @media #{$sm-layout} {
    position: static;
    bottom: 0;
    padding-top: 33px;
  }

  @media #{$md-layout} {
    position: static;
    bottom: 0;
    padding-top: 33px;
  }
  p {
    color: #c6c9d8;
    font-size: 14px;
    opacity: 0.75;
  }
}


